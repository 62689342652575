var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"width":"1220px","centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑客户套餐"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('span',[_vm._v("基本信息")]),_c('a-form-item',{attrs:{"label":"套餐名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入套餐名称' },
                    { max: 100, message: '最多100个字符' } ]
                }]),expression:"['name', {\n                  normalize: this.$lodash.trim,\n                  rules: [\n                    { required: true, message: '请输入套餐名称' },\n                    { max: 100, message: '最多100个字符' },\n                  ]\n                }]"}]})],1),_c('a-form-item',{staticClass:"simiot-display-item",attrs:{"label":"基础套餐名称"}},[_vm._v(" "+_vm._s(_vm.data.base_product_name)+" ")]),_c('a-form-item',{staticClass:"simiot-display-item",attrs:{"label":"套餐周期"}},[_vm._v(" "+_vm._s(_vm.data.service_cycle)+" ")]),_c('a-form-item',{staticClass:"simiot-display-item",attrs:{"label":"套餐容量"}},[_vm._v(" "+_vm._s(_vm.data.package_capacity)+" ")]),_c('a-form-item',{staticClass:"simiot-display-item",attrs:{"label":"语音"}},[_vm._v(" "+_vm._s(_vm.data.voice_capacity)+" ")]),_c('a-form-item',{attrs:{"label":"套餐描述："}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['product_description', {
                  rules: [
                    { max: 200, message: '最多200个字符' } ]
                }]),expression:"['product_description', {\n                  rules: [\n                    { max: 200, message: '最多200个字符' },\n                  ]\n                }]"}]})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCanSetAllowRetailOrSelfSubscribe),expression:"isCanSetAllowRetailOrSelfSubscribe"}]},[_c('span',[_vm._v("零售设置")]),_c('a-form-item',{attrs:{"label":"允许零售"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_allow_retail']),expression:"['is_allow_retail']"}],attrs:{"checked":_vm.isAllowRetail},on:{"change":_vm.changeIsAllowRetail}}),_vm._v(" 允许通过小程序订购 ")],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAllowRetail),expression:"isAllowRetail"}],attrs:{"label":"零售价设置者"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['retail_operator',{
                    initialValue: _vm.data.retail_operator,
                    rules: [
                      { required: _vm.isAllowRetail, message: '请选择零售价设置者' } ]
                  }]),expression:"['retail_operator',{\n                    initialValue: data.retail_operator,\n                    rules: [\n                      { required: isAllowRetail, message: '请选择零售价设置者' },\n                    ]\n                  }]"}],attrs:{"disabled":""}},[_c('a-radio',{attrs:{"value":"platform"}},[_vm._v(" 平台 ")]),_c('a-radio',{attrs:{"value":"user"}},[_vm._v(" 零售商 ")])],1),_c('font',{staticStyle:{"color":"red"}},[_vm._v("(继承自基础套餐)")])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v("自定义功能")]),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"label":"限制订购周期数"}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-form-item',{style:({ display: 'inline-block', width: '15%', marginBottom: 0 })},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_limit_subscribe_cycle']),expression:"['is_limit_subscribe_cycle']"}],attrs:{"checked":_vm.isLimitSubscribeCycle},on:{"change":_vm.changeIsLimitSubscribeCycle}})],1),(_vm.isLimitSubscribeCycle)?_c('a-form-item',{style:({ display: 'inline-block', width: '85%', marginBottom: 0 })},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['limit_subscribe_cycle', {
                          initialValue: _vm.data.limit_subscribe_cycle == -1 ? undefined : _vm.data.limit_subscribe_cycle,
                          rules: [
                            { required: _vm.isLimitSubscribeCycle, message: '请输入订购周期数' }
                          ]
                        }]),expression:"['limit_subscribe_cycle', {\n                          initialValue: data.limit_subscribe_cycle == -1 ? undefined : data.limit_subscribe_cycle,\n                          rules: [\n                            { required: isLimitSubscribeCycle, message: '请输入订购周期数' }\n                          ]\n                        }]"}],staticStyle:{"width":"200px"},attrs:{"min":1,"max":10000000,"step":1,"precision":0}}),_c('span',[_vm._v("个周期")])],1)],1):_vm._e()],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCanSetAllowRetailOrSelfSubscribe),expression:"isCanSetAllowRetailOrSelfSubscribe"}]},[_c('span',[_vm._v("其它设置")]),_c('a-form-item',{attrs:{"label":"允许自订"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_allow_self_subscribe']),expression:"['is_allow_self_subscribe']"}],attrs:{"checked":_vm.isAllowSelfSubscribe},on:{"change":_vm.changeIsAllowSelfSubscribe}}),_vm._v(" 允许通过CMP订购 ")],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('span',[_vm._v("价格设置")]),_c('a-row',{staticStyle:{"padding-left":"2px"},attrs:{"gutter":24}},_vm._l((_vm.favorablePriceLabels),function(label){return _c('a-col',{key:label,attrs:{"span":_vm.favorablePriceSpanSize}},[_vm._v(" "+_vm._s(label)+" ")])}),1),_vm._l((_vm.form.getFieldValue('keys')),function(k,index){return _c('a-row',{key:k,attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":_vm.favorablePriceSpanSize}},[_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([("agents_product_favorable_prices[" + k + "].cycles"), {
                      initialValue: _vm.agentsProductFavorablePrices[k] && _vm.agentsProductFavorablePrices[k].cycles,
                      rules: [
                        { required: true, message: '请输入周期数' },
                        { type: 'integer', message: '请输入 1 - 10000000 之间的整数' },
                        { validator: function (rule, value, callback) { return _vm.checkCycles(rule, value, callback, k, index); } }
                      ]
                    }]),expression:"[`agents_product_favorable_prices[${k}].cycles`, {\n                      initialValue: agentsProductFavorablePrices[k] && agentsProductFavorablePrices[k].cycles,\n                      rules: [\n                        { required: true, message: '请输入周期数' },\n                        { type: 'integer', message: '请输入 1 - 10000000 之间的整数' },\n                        { validator: (rule, value, callback) => checkCycles(rule, value, callback, k, index) }\n                      ]\n                    }]"}],staticStyle:{"width":"120px"},attrs:{"min":1,"max":10000000,"disabled":""},on:{"change":_vm.validateFavorablePriceCycles}})],1)],1),_c('a-col',{attrs:{"span":_vm.favorablePriceSpanSize}},[_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([("agents_product_favorable_prices[" + k + "].floor_price"), {
                      initialValue: _vm.agentsProductFavorablePrices[k] && _vm.agentsProductFavorablePrices[k].floor_price,
                      rules: [
                        { required: true, message: '请输入底价' }
                      ]
                    }]),expression:"[`agents_product_favorable_prices[${k}].floor_price`, {\n                      initialValue: agentsProductFavorablePrices[k] && agentsProductFavorablePrices[k].floor_price,\n                      rules: [\n                        { required: true, message: '请输入底价' }\n                      ]\n                    }]"}],staticStyle:{"width":"120px"},attrs:{"min":0,"max":10000000,"step":0.01,"precision":2,"disabled":_vm.isSaleLogin || _vm.isMerchantSales}})],1)],1),_c('a-col',{attrs:{"span":_vm.favorablePriceSpanSize}},[_c('a-form-item',[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([("agents_product_favorable_prices[" + k + "].distributor_price"), {
                        initialValue: _vm.agentsProductFavorablePrices[k] && _vm.agentsProductFavorablePrices[k].distributor_price,
                        rules: [
                          { required: true, message: '请输入客户价' },
                          { validator: _vm.checkDistributorPrice }
                        ]
                      }]),expression:"[`agents_product_favorable_prices[${k}].distributor_price`, {\n                        initialValue: agentsProductFavorablePrices[k] && agentsProductFavorablePrices[k].distributor_price,\n                        rules: [\n                          { required: true, message: '请输入客户价' },\n                          { validator: checkDistributorPrice }\n                        ]\n                      }]"}],staticStyle:{"width":"120px"},attrs:{"min":0,"max":10000000,"step":0.01,"precision":2,"disabled":_vm.isMerchantSales}}),_c('a-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPlatformRetailOperator && _vm.form.getFieldValue('keys').length > 1 && !_vm.isSaleLogin),expression:"!isPlatformRetailOperator && form.getFieldValue('keys').length > 1 && !isSaleLogin"}],staticClass:"dynamic-delete-button",style:({ display: 'inline-block'}),attrs:{"type":"minus-circle-o","disabled":_vm.form.getFieldValue('keys').length === 1},on:{"click":function () { return _vm.removeSetPriceItem(k); }}})],1)],1)],1),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPlatformRetailOperator),expression:"isPlatformRetailOperator"}],attrs:{"span":_vm.favorablePriceSpanSize}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-form-item',[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([("agents_product_favorable_prices[" + k + "].retail_price"), {
                          initialValue: _vm.agentsProductFavorablePrices[k] && _vm.agentsProductFavorablePrices[k].retail_price,
                          rules: [
                            { type: 'number', message: '请输入 1 - 10000000 之间的数字' },
                            { validator: function (rule, value, callback) { return _vm.checkRetailPrice(rule, value, callback, k); } }
                          ]
                        }]),expression:"[`agents_product_favorable_prices[${k}].retail_price`, {\n                          initialValue: agentsProductFavorablePrices[k] && agentsProductFavorablePrices[k].retail_price,\n                          rules: [\n                            { type: 'number', message: '请输入 1 - 10000000 之间的数字' },\n                            { validator: (rule, value, callback) => checkRetailPrice(rule, value, callback, k) }\n                          ]\n                        }]"}],staticStyle:{"width":"120px"},attrs:{"min":0,"step":0.01,"max":10000000,"precision":2}}),_c('a-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.getFieldValue('keys').length > 1 && !_vm.isSaleLogin),expression:"form.getFieldValue('keys').length > 1 && !isSaleLogin"}],staticClass:"dynamic-delete-button",style:({ display: 'inline-block'}),attrs:{"type":"minus-circle-o","disabled":_vm.form.getFieldValue('keys').length === 1},on:{"click":function () { return _vm.removeSetPriceItem(k); }}})],1)],1)],1)],1)],1)})],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }